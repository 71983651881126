import React from "react";
import ElegantShotClaimPage from "./ElegantShotClaimPage";

function App() {
  return (
    <div>
      <ElegantShotClaimPage />
    </div>
  );
}

export default App;